import dayjs from 'dayjs'
import { ReceiptImage } from 'src/slices/services/api'
import {
  ReportMessage,
  reportTypeEnum,
} from 'src/components/molecules/ReportMessages'
import { Option } from 'src/components/molecules/SelectWithGroupOption'
import { DATE_FORMAT, formatDateTime } from 'src/utils/date'
import { SalesReportType } from './type'

export const genReportMessages = (
  reports?: SalesReportType[]
): ReportMessage[] => {
  if (!reports || reports.length === 0) return []

  const reportMessages: ReportMessage[] = []

  dayjs.locale('ja')

  const initialReport = reports[0]

  if (reports[0].reportMessage) {
    reportMessages.push({
      id: initialReport.id,
      date: formatDateTime(initialReport.updatedAt),
      reportType: reportTypeEnum.Normal,
      messageBody: initialReport.reportMessage,
    })
  }

  const { resentRegisters } = initialReport

  if (resentRegisters.length === 0) {
    return reportMessages
  }

  resentRegisters.forEach((resentRegister) => {
    if (resentRegister.resentMessage) {
      reportMessages.push({
        id: resentRegister.id,
        date: dayjs(resentRegister.updatedAt).format(DATE_FORMAT),
        reportType: reportTypeEnum.Resent,
        messageBody: resentRegister.resentMessage,
        reportIndex: resentRegister.index,
      })
    }
  })
  return reportMessages.sort((optionA, optionB) => {
    return dayjs(optionA.date, DATE_FORMAT).isBefore(
      dayjs(optionB.date, DATE_FORMAT)
    )
      ? 1
      : -1
  })
}

export const genSelectDateOptions = (reports?: SalesReportType[]): Option[] => {
  if (!reports || reports.length === 0) return []

  const options: Option[] = []

  dayjs.locale('ja')

  const initialReport = reports[0]

  options.push({
    value: initialReport.id,
    title: formatDateTime(initialReport.updatedAt),
  })

  const { resentRegisters } = initialReport

  if (resentRegisters.length === 0) {
    return options
  }

  resentRegisters.forEach((resentRegister) => {
    options.push({
      value: resentRegister.id,
      title: formatDateTime(resentRegister.updatedAt),
    })
  })
  return options.sort((optionA, optionB) => {
    return dayjs(optionA.title, DATE_FORMAT).isBefore(
      dayjs(optionB.title, DATE_FORMAT)
    )
      ? 1
      : -1
  })
}

export const findSelectedResentImage = (
  id: string,
  reports: SalesReportType[]
): ReceiptImage | undefined => {
  if (!reports || reports.length === 0) return undefined

  const isIdInReports = reports.some((report) => report.id === id)

  if (isIdInReports) return undefined

  const { resentRegisters } = reports[0]

  if (resentRegisters.length === 0) return undefined

  return (
    resentRegisters.find((resentRegister) => resentRegister.id === id)
      ?.receiptImage || undefined
  )
}
