import { formatDateWithTimeAndWeekday } from 'src/utils/date'
import { Filter } from '../../components/molecules/TenantFilter'
import { Tenant as TemplateTenant } from '../../components/molecules/TenantList'
import { TenantStatusEnum, OrgTenant } from '../../slices/services/api/api'
import Path from '../../routes/path'

interface StoreTenantsTabPresenter {
  filters(): Filter[]
  statusQueryParams(
    formValue: TenantStatusEnum | string
  ): TenantStatusEnum | undefined
  ocrQueryParams(formValue: number | string): boolean | undefined
  ocrFormValue(queryParam: boolean | undefined): number | string
  mapTemplateStore(orgCode: string, tenant: OrgTenant): TemplateTenant
}

export const optionDefaultValue = ''

const Presenter: StoreTenantsTabPresenter = {
  filters: (): Filter[] => {
    const baseFilters = [
      {
        name: 'status',
        placeholder: 'すべてのステータス',
        options: [
          { index: 0, title: 'すべてのステータス', value: optionDefaultValue },
          { index: 1, title: '利用開始前', value: TenantStatusEnum.NotReady },
          { index: 2, title: '利用中', value: TenantStatusEnum.Ready },
          { index: 3, title: '利用不可', value: TenantStatusEnum.Closed },
        ],
      },
    ]
    return baseFilters.map((filter) => {
      return {
        ...filter,
      }
    })
  },
  statusQueryParams: (
    formValue: string | TenantStatusEnum
  ): TenantStatusEnum | undefined => {
    return formValue === optionDefaultValue
      ? undefined
      : (formValue as TenantStatusEnum)
  },
  ocrQueryParams: (formValue: number | string): boolean | undefined => {
    return formValue === optionDefaultValue ? undefined : Boolean(formValue)
  },
  ocrFormValue: (queryValue: boolean | undefined): number | string => {
    return queryValue === undefined ? optionDefaultValue : Number(queryValue)
  },
  mapTemplateStore: (orgCode: string, tenant: OrgTenant): TemplateTenant => {
    return {
      name: tenant.name,
      code: tenant.code,
      status: tenant.status,
      storeName: tenant.store.name,
      trainedAt: tenant.trainedAt
        ? formatDateWithTimeAndWeekday(tenant.trainedAt)
        : '練習モード実施なし',
      to: Path.tenant(orgCode, tenant.store.code, tenant.code),
    }
  },
}

export default Presenter
