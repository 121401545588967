import { ReportStatusEnum } from 'src/slices/services/api'
import {
  BadgeColor,
  FillTypeColor,
  MemoType,
  ReportStatusMap,
  UpdateStatusType,
} from './type'

export const reportStatusMap: ReportStatusMap = {
  [ReportStatusEnum.Waiting]: {
    badgeColor: 'vermilion',
    fillTypeColor: 'color',
    title: '報告待ち',
  },
  [ReportStatusEnum.LeftHalfway]: {
    badgeColor: 'vermilion',
    fillTypeColor: 'color',
    title: '報告中断',
  },
  [ReportStatusEnum.Reported]: {
    badgeColor: 'green04',
    fillTypeColor: 'color',
    title: '報告済み',
  },
  [ReportStatusEnum.ImageResent]: {
    badgeColor: 'lightGreen',
    fillTypeColor: 'color',
    title: '再送済み',
  },
  [ReportStatusEnum.FinalizedReportSubmitted]: {
    badgeColor: 'lightYellow',
    fillTypeColor: 'color',
    title: '確定済み',
  },
  [ReportStatusEnum.FinalizedReportSynced]: {
    badgeColor: 'gray',
    fillTypeColor: 'color',
    title: '連携済み',
  },
}

export const getReportStatusTitle = (role: ReportStatusEnum): string => {
  return reportStatusMap[role].title ?? ''
}

export const getReportStatusBadgeColor = (
  status: ReportStatusEnum
): BadgeColor => {
  return reportStatusMap[status].badgeColor ?? 'gray'
}

export const getReportStatusFillTypeColor = (
  status: ReportStatusEnum
): FillTypeColor => {
  return reportStatusMap[status].fillTypeColor ?? 'transparent'
}

export const statusCheckOptions: {
  label: string
  value: ReportStatusEnum
}[] = [
  {
    label: getReportStatusTitle(ReportStatusEnum.Waiting),
    value: ReportStatusEnum.Waiting,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.LeftHalfway),
    value: ReportStatusEnum.LeftHalfway,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.Reported),
    value: ReportStatusEnum.Reported,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.ImageResent),
    value: ReportStatusEnum.ImageResent,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.FinalizedReportSubmitted),
    value: ReportStatusEnum.FinalizedReportSubmitted,
  },
  {
    label: getReportStatusTitle(ReportStatusEnum.FinalizedReportSynced),
    value: ReportStatusEnum.FinalizedReportSynced,
  },
]

const memoMap = {
  reportMemo: '補足事項',
  manageMemo: '管理者メモ',
}

export const getMemoTitle = (memo?: MemoType): string => {
  return memo ? memoMap[memo] ?? '' : ''
}

export const memoCheckOptions: { label: string; value: MemoType }[] = [
  { label: getMemoTitle('reportMemo'), value: 'reportMemo' },
  { label: getMemoTitle('manageMemo'), value: 'manageMemo' },
]

export const popupOptions: UpdateStatusType[] = [
  'revertReported',
  'bulkFinalize',
]

export const checkReportStatusBeforeReported = (
  reportStatus: ReportStatusEnum
): boolean => {
  return (
    reportStatus === ReportStatusEnum.Waiting ||
    reportStatus === ReportStatusEnum.LeftHalfway
  )
}
