/* eslint-disable react/prop-types */
import React, { memo, useEffect, useRef, useState } from 'react'
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import clsx from 'clsx'
import CancelButton from '../../atoms/CancelButton'
import styles from './styles.module.scss'
import {
  getAdjustedCursorPosition,
  getHalfWidthNumericString,
  getValueWithComma,
} from './functions'
import { RegexTrailingZerosAndDot } from '../../../utils/regularExpression'

export type TextFieldWithCommaProps = {
  name: string
  defaultValue?: number
  placeholder?: string
  options: RegisterOptions
  onKeyPress: () => void
} & React.StyledProps

const TextFieldWithComma: React.StyledFC<TextFieldWithCommaProps> = memo(
  ({ className, name, defaultValue, placeholder, options, onKeyPress }) => {
    const { register, setValue, control, clearErrors } = useFormContext()
    const watchedValue = useWatch({ name, defaultValue })
    const [valueWithComma, setValueWithComma] = useState<string>(
      defaultValue?.toLocaleString() ?? '0'
    )
    const [cursorPosition, setCursorPosition] = useState<number | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleClickClear = () => {
      setValueWithComma('')
      setValue(name, '')
      clearErrors(name)
    }

    const handleCursorChange = () => {
      if (inputRef.current) {
        setCursorPosition(inputRef.current.selectionStart)
      }
    }

    const handleInputValueChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ): number | '' => {
      const inputValue = e.target.value
      const inputValueWithoutComma = getHalfWidthNumericString(inputValue)

      if (!inputValueWithoutComma) return ''

      const inputValueWithComma = getValueWithComma(inputValueWithoutComma)
      const adjustCursorPosition = getAdjustedCursorPosition(
        e.target.selectionStart,
        valueWithComma,
        inputValueWithComma
      )

      setCursorPosition(adjustCursorPosition)
      setValueWithComma(inputValueWithComma)
      return Number(inputValueWithoutComma)
    }

    // NOTE: フォーカスから離れたときに、整数末尾の「.」または、小数末尾の0が残っている場合は削除する
    const removeTrailingChar = () => {
      const newValue = valueWithComma.replace(RegexTrailingZerosAndDot, '$1')
      if (newValue !== valueWithComma) {
        setValueWithComma(newValue)
        setValue(name, Number(newValue.replace(/,/g, '')) || '')
      }
    }

    // NOTE: 初期値をformに適用させる
    useEffect(() => {
      setValue(name, defaultValue ?? 0)
    }, [name, setValue, defaultValue])

    // NOTE: component外部からformの値を変更する
    // ユーザーの手入力と区別するために、外部からの変更の際はformの値を文字列で扱う
    useEffect(() => {
      if (watchedValue === '') {
        setValueWithComma('')
        return
      }
      if (typeof watchedValue === 'string') {
        const numValue = Number(watchedValue.replace(/,/g, ''))
        setValueWithComma(numValue.toLocaleString() ?? '0')
        setValue(name, numValue)
      }
    }, [watchedValue, name, setValue])

    useEffect(() => {
      if (cursorPosition !== null && inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
      }
    }, [valueWithComma, cursorPosition])

    return (
      <Controller
        name={name}
        control={control}
        rules={options}
        render={({ field }) => (
          <div className={clsx(className, styles.textField)}>
            <input
              {...register(name)}
              ref={inputRef}
              className={styles.input}
              name={name}
              type="text"
              value={valueWithComma}
              placeholder={placeholder}
              onBlur={() => {
                field.onBlur()
                removeTrailingChar()
              }}
              onKeyUp={handleCursorChange}
              onChange={(e) => {
                field.onChange(handleInputValueChange(e))
                onKeyPress()
              }}
            />
            {Boolean(watchedValue ?? defaultValue) && (
              <CancelButton
                className={styles.cancelButton}
                onClick={handleClickClear}
              />
            )}
          </div>
        )}
      />
    )
  }
)
TextFieldWithComma.displayName = 'TextFieldWithComma'

export default TextFieldWithComma
