/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.locale('ja')

export const DATE_FORMAT = 'MM.DD(ddd) HH:mm'

export const formatFullDate = (day: string | Date | dayjs.Dayjs): string => {
  return dayjs(day).format('YYYY年M月D日 (ddd)')
}

export const formatSimpleDate = (day: string | Date | dayjs.Dayjs): string => {
  return dayjs(day).format('YYYY-MM-DD')
}

export const formatShortDateTime = (
  day: string | Date | dayjs.Dayjs
): string => {
  return dayjs(day).format('MM月DD日 (ddd) HH:mm')
}

export const formatEightDigitDate = (
  day: string | Date | dayjs.Dayjs
): string => {
  return dayjs(day).format('YYYYMMDD')
}

export const formatDateWithDayOfWeek = (
  day: string | Date | dayjs.Dayjs
): string => {
  return dayjs(day).format('YYYY-MM-DD(dd)')
}

export const formatDateTime = (day: string | Date | dayjs.Dayjs): string => {
  return dayjs(day).format('MM-DD(dd) HH:mm')
}

export const formatDateWithTimeAndWeekday = (
  day: string | Date | dayjs.Dayjs | undefined
): string => {
  return dayjs(day).format('YYYY-MM-DD(dd) HH:mm')
}

export const formatDateTimeWithDayOfWeek = (
  day: string | Date | dayjs.Dayjs | undefined
): string => {
  return dayjs(day).format('YYYY/MM/DD(ddd) HH:mm')
}
