import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import html2canvas from 'html2canvas'
import JsPdf from 'jspdf'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { BreadcrumbsProps } from '../../components/molecules/Breadcrumbs'
import { Section } from '../../components/molecules/QRCodeModal'
import TenantDetailTemplate from '../../components/templates/TenantDetail'
import { ToastTriggerContext } from '../../context/toast.context'
import {
  getStore,
  selectStoresRequestStateByParams,
  selectStoreByCode,
} from '../../slices/stores/storesSlice'
import {
  getTenant,
  patchTenantPrintedAt,
  selectTenantByCode,
  selectTenantStateByCode,
  revertTenantStatus,
} from '../../slices/tenants/tenantsSlice'
import useAppTitle from '../../hooks/useAppTitle'
import { TenantPathParams } from '../../routes/path'
import { useAppSelector, useAppDispatch } from '../../store'
import { trimer } from '../../utils/string'
import CalculationTab from '../CalculationTab'
import OCRFormatTab from '../OCRFormatTab'
import Presenter from './presenter'
import TenantDetailTab from '../../features/tenantDetail/pages/TenantDetailTab'
import { RoleEnum } from '../../slices/services/api'

const tabContents = [
  { name: 'tenant-detail', node: <TenantDetailTab key={0} /> },
  { name: 'calculation', node: <CalculationTab key={1} /> },
  { name: 'ocr-format', node: <OCRFormatTab key={2} /> },
]

const TenantDetail: React.FC = (): ReactElement => {
  useAppTitle('テナント詳細')
  const toastContext = useContext(ToastTriggerContext)
  const dispatch = useAppDispatch()
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, 'tenant-detail'),
  })
  const { tab } = query
  const [selectTabIndex, setSelectTabIndex] = useState<number>(0)
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<
    BreadcrumbsProps['paths']
  >([])
  const [showModal, setShowModal] = useState(false)
  const { currentRoot } = useAppSelector((state) => state.navigation)

  const tenant = useAppSelector(
    selectTenantByCode({ orgCode, storeCode, tenantCode })
  )
  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const requestState = useAppSelector(
    selectTenantStateByCode({ orgCode, storeCode, tenantCode })
  )
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  const role: RoleEnum | undefined = useAppSelector(
    (state) => state.auth.currentAuthorization?.role
  )

  useEffect(() => {
    if (requestState.status === 'idle') {
      dispatch(getTenant({ orgCode, storeCode, tenantCode }))
    }
  }, [dispatch, requestState, orgCode, storeCode, tenantCode])

  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])

  useEffect(() => {
    if (tab) {
      const index = tabContents.findIndex((content) => content.name === tab)
      setSelectTabIndex(index)
    }
  }, [tab])

  const onSelectTab = (index: number) => {
    setQuery({ tab: tabContents[index].name })
  }

  const handleClickShowModal = () => {
    setShowModal(true)
  }
  const handleClickClose = () => {
    setShowModal(false)
  }

  const downloadPdf = (elementId: string) => {
    const fileName = `zero_${tenant?.code}_${trimer(tenant?.name)}`

    const input = document.getElementById(elementId)
    if (!input) return

    html2canvas(input, { scale: 5 }).then((canvas) => {
      const imageData = canvas.toDataURL('image/jpeg')
      const pdf = new JsPdf({ orientation: 'l', format: 'a4' })

      // NOTE: PDF生成時のサイズ感・場所を整えるため、pdfコンストラクタ・canvasのサイズから計算している

      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()

      const ratio = pageHeight / canvas.height

      const canvasWidth = canvas.width * ratio
      const canvasHeight = canvas.height * ratio

      const marginX = (pageWidth - canvasWidth) / 2
      const marginY = (pageHeight - canvasHeight) / 2

      pdf.addImage(
        imageData,
        'JPEG',
        marginX,
        marginY,
        canvasWidth,
        canvasHeight
      )
      pdf.save(`${fileName}.pdf`)
    })
    setShowModal(false)
  }

  // API State
  const { status } = useAppSelector((state) => state.entities.tenants)

  useEffect(() => {
    if (status === 'idle' || status === 'loading') return undefined
    if (status === 'succeeded') {
      toastContext.sendToast({
        variant: 'success',
        title: 'ダウンロードが完了しました',
      })
    }
    if (status === 'failed') {
      toastContext.sendToast({
        variant: 'error',
        title: 'ダウンロードが失敗しました',
      })
    }
    return () => {
      dispatch(revertTenantStatus())
    }
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickDownload = (elementId: string) => {
    if (!tenant) return

    downloadPdf(elementId)
    dispatch(patchTenantPrintedAt({ orgCode, tenantId: tenant.id }))
  }

  const sections: Section[] = [
    { title: '店舗名称', value: trimer(store?.name) },
    { title: 'テナントコード', value: tenant?.code ?? '' },
    { title: 'テナント名', value: trimer(tenant?.name) },
  ]

  useEffect(() => {
    setBreadcrumbPaths(
      Presenter.breadcrumbs(
        orgCode,
        storeCode,
        store?.name,
        tenant?.name,
        currentRoot
      )
    )
  }, [orgCode, storeCode, tenant, store, currentRoot])

  return (
    <TenantDetailTemplate
      // TenantDetailTemplateのPropsの定義をstring | undefinedにする
      tenantName={tenant?.name ?? ''}
      tenantCode={tenant?.code ?? ''}
      status={tenant?.status ?? 'not_ready'}
      breadcrumbPaths={breadcrumbPaths}
      tabOptions={Presenter.tabOptions(orgCode)}
      tabContents={tabContents.map((content) => content.node)}
      tabIndex={selectTabIndex}
      onSelectTab={onSelectTab}
      isLoading={requestState.status === 'loading'}
      showButtonText="QRコード表示"
      downloadButtonText="ダウンロードする"
      modalTitle="テナント認証用QRコード"
      modalOpen={showModal}
      disableBackdropClick={false}
      sections={sections}
      qrPdfElementId="qr-code-pdf"
      qrSrc={`${orgCode}/${storeCode}/${tenantCode}`}
      qrSize={200}
      role={role}
      onClickShowModal={handleClickShowModal}
      onClickDownload={handleClickDownload}
      onClickClose={handleClickClose}
    />
  )
}

export default TenantDetail
