import React, { useEffect, useState, useRef, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import color from 'src/styles/color'
import Icon, { IconName } from '../Icon'

export type RotationImageProps = {
  src: string
  alt: string
  pictureNumber: string
} & React.StyledProps

type Angle = 0 | 90 | 180 | 270

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
  },
  img: {
    top: 0,
    left: 0,
    transformOrigin: 'top left',
    transitionDuration: '100ms', // animationを表示するかは要検討
  },
  button: {
    padding: '0',
    '&:disabled': {
      opacity: 0.2,
    },
    '& > svg': {
      width: '30px',
      height: '30px',
    },
  },
  rightButton: {
    position: 'absolute',
    zIndex: 1,
    top: '4px',
    right: '4px',
  },
  angle90: {
    transform: 'rotate( 90deg )',
  },
  angle180: {
    transform: 'rotate( 180deg )',
  },
  angle270: {
    transform: 'rotate( 270deg )',
  },
  pictureNumberWrapper: {
    position: 'absolute',
    zIndex: 1,
    top: 4,
    left: 4,
    display: 'flex',
    width: 80,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 60,
    backgroundColor: color.black500,

    '@media (width <= 1280px)': {
      width: 72,
      height: 32,
    },
  },
  pictureNumber: {
    zIndex: 1,
    color: color.white,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 22,

    '@media (width <= 1280px)': {
      fontSize: 14,
    },
  },
})

const DUMMY_IMAGE_HEIGHT = 300

const RotationImage: React.StyledFC<RotationImageProps> = ({
  src,
  alt,
  pictureNumber,
}: RotationImageProps) => {
  const classes = useStyles()
  const containerRef = useRef<HTMLImageElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const originalContainerWidthRef = useRef<number>()
  const originalNaturalWidthRef = useRef<number>()
  const originalNaturalHeightRef = useRef<number>()
  const [angle, setAngle] = useState<Angle>(0)
  const [imgWidth, setImgWidth] = useState<number>()
  const [imgHeight, setImgHeight] = useState<number>()
  const [imgMarginLeft, setImgMarginLeft] = useState<number>()
  const [imgMarginTop, setImgMarginTop] = useState<number>()
  const [containerHeight, setContainerHeight] = useState<number>()
  const [isUseRotationFeature, setIsUseRotationFeature] =
    useState<boolean>(false)

  const getRotatedImageClientHeight = (): number => {
    if (
      originalNaturalWidthRef.current &&
      originalNaturalHeightRef.current &&
      originalContainerWidthRef.current
    ) {
      return (
        (originalContainerWidthRef.current * originalNaturalWidthRef.current) /
        originalNaturalHeightRef.current
      )
    }
    return DUMMY_IMAGE_HEIGHT // NOTE: この条件に陥る場合は、isUseRotationFeatureがfalseになるので、実際に利用されることは無いが、エラー回避のために設定している
  }

  const getOriginalImageClientHeight = (): number => {
    if (
      originalNaturalWidthRef.current &&
      originalNaturalHeightRef.current &&
      originalContainerWidthRef.current
    ) {
      return (
        (originalContainerWidthRef.current * originalNaturalHeightRef.current) /
        originalNaturalWidthRef.current
      )
    }
    return DUMMY_IMAGE_HEIGHT // NOTE: この条件に陥る場合は、isUseRotationFeatureがfalseになるので、実際に利用されることは無いが、エラー回避のために設定している
  }

  const convertToRotatedSize = () => {
    const height = getRotatedImageClientHeight()
    setImgWidth(height)
    setImgHeight(originalContainerWidthRef.current)
    setContainerHeight(height)
  }

  const convertToOriginalSize = useCallback(() => {
    const height = getOriginalImageClientHeight()
    setImgWidth(originalContainerWidthRef.current)
    setImgHeight(height)
    setContainerHeight(height)
  }, [])

  const retrieveSizes = () => {
    originalContainerWidthRef.current = Number(
      containerRef.current?.clientWidth
    )
    originalNaturalWidthRef.current = Number(imgRef.current?.naturalWidth)
    originalNaturalHeightRef.current = Number(imgRef.current?.naturalHeight)
  }

  const isProperSizeExist = (): boolean => {
    return Boolean(
      originalNaturalWidthRef.current &&
        originalNaturalHeightRef.current &&
        originalContainerWidthRef.current
    )
  }
  const applyRotationFeature = useCallback(() => {
    convertToOriginalSize()
    setIsUseRotationFeature(true)
  }, [convertToOriginalSize])

  useEffect(() => {
    retrieveSizes()
    if (isProperSizeExist()) {
      applyRotationFeature()
    } else {
      setIsUseRotationFeature(false)

      // NOTE: 初期ロードでは稀に、画像のサイズが取得できないことがあるため、少し待ってから再度サイズを取得する
      setTimeout(() => {
        retrieveSizes()
        if (isProperSizeExist()) {
          applyRotationFeature()
        }
      }, 500)
    }
  }, [applyRotationFeature])

  const rotateImage0 = () => {
    setAngle(0)
    convertToOriginalSize()
    setImgMarginTop(0)
    setImgMarginLeft(0)
  }

  const rotateImage90 = () => {
    setAngle(90)
    convertToRotatedSize()
    setImgMarginTop(0)
    setImgMarginLeft(originalContainerWidthRef.current ?? 0)
  }

  const rotateImage180 = () => {
    setAngle(180)
    convertToOriginalSize()
    const originalImageClientHeight = getOriginalImageClientHeight()
    setImgMarginTop(originalImageClientHeight)
    setImgMarginLeft(originalContainerWidthRef.current ?? 0)
  }

  const rotateImage270 = () => {
    setAngle(270)
    convertToRotatedSize()
    const rotatedHeight = getRotatedImageClientHeight()
    setImgMarginTop(rotatedHeight)
    setImgMarginLeft(0)
  }

  const clickRightButton = (
    event: React.MouseEvent<HTMLElement>,
    currentAngle: Angle
  ) => {
    event.stopPropagation()
    switch (currentAngle) {
      case 0:
        rotateImage90()
        break
      case 90:
        rotateImage180()
        break
      case 180:
        rotateImage270()
        break
      case 270:
        rotateImage0()
        break
      default:
    }
  }

  const rotationClass = (ang: Angle): string => {
    switch (ang) {
      case 90:
        return classes.angle90
      case 180:
        return classes.angle180
      case 270:
        return classes.angle270
      default:
        return ''
    }
  }

  return (
    <div
      ref={containerRef}
      className={classes.container}
      style={{ height: containerHeight }}
    >
      <Box className={classes.pictureNumberWrapper}>
        <Typography className={classes.pictureNumber}>
          {pictureNumber}
        </Typography>
      </Box>
      {isUseRotationFeature && (
        <IconButton
          className={clsx(classes.rightButton, classes.button)}
          type="button"
          onClick={(event) => {
            clickRightButton(event, angle)
          }}
        >
          <Icon icon={'rotateRight' as IconName} />
        </IconButton>
      )}
      <img
        ref={imgRef}
        style={{
          height: isUseRotationFeature ? imgHeight : 'auto',
          width: isUseRotationFeature ? imgWidth : '100%',
          marginLeft: isUseRotationFeature ? imgMarginLeft : 0,
          marginTop: isUseRotationFeature ? imgMarginTop : 0,
          position: isUseRotationFeature ? 'absolute' : 'relative',
        }}
        className={clsx(classes.img, rotationClass(angle))}
        src={src}
        alt={alt}
        draggable={false}
      />
    </div>
  )
}

export default RotationImage
