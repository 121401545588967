import { Member, RoleEnum } from '../../../../slices/services/api'

const WEDEmail = '@wed.company'
const GVEmail = '@growth-verse.ai'

// TODO: test書く
// FIXME: MemberList, MemberUpdateで共通の関数なのでファイルの移動を検討する
// NOTE: MemberとMemberUpdate共通
export const adjustMembers = (
  originalMembers: Member[],
  selfEmail: string,
  selfRole: RoleEnum
): Member[] | null => {
  const selfMember: Member | undefined = originalMembers?.find(
    // NOTE: emailの大文字小文字は無視する
    (data) => data.email.toLowerCase() === selfEmail.toLowerCase()
  )
  if (!selfMember) return null

  const membersWithoutWEDAndGV = originalMembers.filter(
    (member) =>
      !member.email.endsWith(WEDEmail) && !member.email.endsWith(GVEmail)
  )

  if (selfMember.assignedStores) {
    const selfAssignedStoresStoreIds = selfMember.assignedStores.map(
      (data) => data.id
    )
    const adjustedMember =
      membersWithoutWEDAndGV?.map((member) => {
        return {
          id: member.id,
          role: member.role,
          email: member.email,
          name: member.name,
          hasAllStoresPermission: member.hasAllStoresPermission,
          assignedStores: member.assignedStores?.filter((store) =>
            selfAssignedStoresStoreIds.includes(store.id)
          ),
          active: member.active,
        }
      }) || []

    if (selfRole === RoleEnum.AdminStoreManager) {
      return adjustedMember.filter(
        (member) => member.role === RoleEnum.EditorStoreManager
      )
    }
    return adjustedMember
  }
  return (
    membersWithoutWEDAndGV?.filter((member) => member.email !== selfEmail) || []
  )
}
