import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'
import NextImageLoader from 'src/components/molecules/NextImageLoader'
import PopupChild from 'src/components/molecules/PopupChild'
import UpdateButton from 'src/components/molecules/UpdateButton'
import Modal from 'src/components/molecules/Modal'
import LoadingModal from 'src/components/molecules/LoadingModal'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import SmallGuide from 'src/components/molecules/SmallGuide'
import listStyles from '../../../styles/list-styles.module.scss'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  AdminAdminSalesReportManagerRoles,
  isOneOfRoles,
} from '../../../domain/role'
import {
  ReportStatusEnum,
  RoleEnum,
  TenantSalesReportSummary,
} from '../../../slices/services/api'

import TenantSalesReportSummaryHeader, {
  TenantSalesReportSummaryHeaderProps,
} from '../components/Header'
import TenantSalesReportSummaryTable from '../components/Table'
import { TenantSalesReportSummaryFilterBar } from '../components/FilterBar'
import { TenantSalesReportSummarySearchDate } from '../components/SearchDate'
import DownloadCSVButton from '../components/DownloadCSVButton'
import FinalizeReportButton from '../components/FinalizeReportButton'
import UpdateStatusMenu from '../components/UpdateStatusMenu'
import style from './styles.module.scss'
import { INITIAL_STATE, reducerFunc } from '../filterReducer'
import { FilterParamType, UpdateStatusType } from '../type'
import UpdateStatusConfirmDescription from '../components/UpdateStatusConfirmDescription'
import SalesDateCount from '../components/SalesDateCount'
import { ENABLE_DOWNLOAD_CSV_ORG_CODES } from '../../../domain/org'
import { formatDateWithDayOfWeek } from '../../../utils/date'

export type TenantSalesReportSummaryProps = {
  breadcrumbs: TenantSalesReportSummaryHeaderProps['breadcrumbs']
  storeName: string
  storeCode: string
  isReadLoaded?: boolean
  isUpdateLoading?: boolean
  isCSVDownloading?: boolean
  role?: RoleEnum
  summaries: TenantSalesReportSummary[]
  orgCode: string
  date: string
  onClickRevertReported: (selectedSalesDateIds: string[]) => void
  onClickBulkFinalization: (selectedSalesDateIds: string[]) => void
  onClickStartFinalization: (selectedTenantCodes: string[]) => void
  onClickSend: (selectedSalesDateIds: string[]) => void
  onChangeDate: (newDate: string) => void
  onDownloadCSV: () => Promise<void>
  onReloadPage: () => void
}

const TemplatesTenantSalesReportSummary: React.StyledFC<
  TenantSalesReportSummaryProps
> = ({
  role,
  breadcrumbs,
  storeName,
  storeCode,
  summaries,
  isReadLoaded,
  isUpdateLoading,
  isCSVDownloading,
  orgCode,
  date,
  onChangeDate,
  onClickRevertReported,
  onClickBulkFinalization,
  onClickStartFinalization,
  onClickSend,
  onDownloadCSV,
  onReloadPage,
}: TenantSalesReportSummaryProps) => {
  const [summariesState, dispatch] = useReducer(reducerFunc, INITIAL_STATE)
  const [selectedUpdateStatusType, setSelectedUpdateStatusType] =
    useState<UpdateStatusType>(undefined)
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] =
    useState<boolean>(false)
  const [isDownloadCSVModalOpen, setIsDownloadCSVModalOpen] =
    useState<boolean>(false)
  const [isReadyToLoadNextImage, setIsReadyToLoadNextImage] =
    useState<boolean>(false)
  const [firstDisplayTenantCode, setFirstDisplayTenantCode] = useState<string>()

  const handleClickUpdateStatusType = (updateStatusType: UpdateStatusType) => {
    setSelectedUpdateStatusType(updateStatusType)
    setIsUpdateStatusModalOpen(true)
  }

  useEffect(() => {
    dispatch({
      type: 'UPDATE_SUMMARIES',
      payload: { allSummaries: summaries },
    })
  }, [summaries])

  const showUpdateSalesReportStatusByRole = useCallback((): boolean => {
    return isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
      role
    )
  }, [role])

  const showTenantSalesReportPageByRole = useCallback((): boolean => {
    return isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles,
      role
    )
  }, [role])

  const showDownloadCSVByRole = useCallback((): boolean => {
    return isOneOfRoles(AdminAdminSalesReportManagerRoles, role)
  }, [role])

  useEffect(() => {
    // NOTE: ユーザーがチェックを入れてから次のチェックを入れるまでの間隔が大体~500ms程度なので、setTimeを使用して最後のチェックから500ms待ってからロードを開始している
    // 1ページ目に表示されるテナントの画像以外を事前ロードする必要はないため、500ms待つことで無駄なロードを防ぐ事ができる
    const timeout = summariesState.isAllChecked ? 0 : 500
    const timeoutId = setTimeout(() => {
      if (summariesState.firstCheckedTenantCode) {
        setFirstDisplayTenantCode(summariesState.firstCheckedTenantCode)
        // NOTE: isReadyToLoadNextImageがtrueになると、画像の事前ロードを行うNextImageLoaderが呼び出される
        setIsReadyToLoadNextImage(true)
      }
    }, timeout)
    // NOTE: 操作が続けられた場合は、その時点で一度timeoutをクリアする
    return () => {
      setIsReadyToLoadNextImage(false)
      clearTimeout(timeoutId)
    }
  }, [
    summariesState.allSummaries,
    summariesState.checkStatusList,
    summariesState.firstCheckedTenantCode,
    summariesState.isAllChecked,
  ])

  const handleChangeCheckStatus = (tenantCode: string) => {
    dispatch({
      type: 'UPDATE_CHECK_STATUS',
      payload: { checkedTenantCode: tenantCode },
    })
  }

  const handleChangeAllCheckStatus = () => {
    dispatch({ type: 'UPDATE_ALL_CHECK_STATUS' })
  }

  const handleClickDisplayDownloadCSVModal = () => {
    setIsDownloadCSVModalOpen(true)
  }

  const handleClickFinalization = () => {
    const selectedTenantCodes = summariesState.allSummaries
      .filter((summary) => summariesState.checkStatusList[summary.tenant.code])
      .map((summary) => summary.tenant.code)
    if (selectedTenantCodes && selectedTenantCodes.length > 0) {
      onClickStartFinalization(selectedTenantCodes)
    }
  }
  const handleClickSend = () => {
    const ids: string[] = []
    Object.keys(summariesState.checkStatusList).forEach((tenantCode) => {
      const foundSummary = summaries.find(
        (summary) => summary.tenant.code === tenantCode
      )
      if (
        foundSummary?.reportStatus === ReportStatusEnum.FinalizedReportSubmitted
      ) {
        ids.push(foundSummary.id)
      }
    })
    onClickSend(ids)
  }

  const handleChangeFilterParam = (param: FilterParamType) => {
    dispatch({
      type: 'UPDATE_FILTER_PARAM',
      payload: { filterParam: param },
    })
  }
  const handleChangeDate = (newDate: string) => {
    dispatch({ type: 'RESET_SUMMARIES' })
    onChangeDate(newDate)
  }

  const handleClickUpdateStatusButton = () => {
    dispatch({ type: 'RESET_SUMMARIES' })
    setIsUpdateStatusModalOpen(false)
    if (selectedUpdateStatusType === 'revertReported') {
      onClickRevertReported(summariesState.checkSalesDateIds)
      return
    }
    onClickBulkFinalization(summariesState.checkSalesDateIds)
  }

  const handleClickDownloadCSVButton = () => {
    setIsDownloadCSVModalOpen(false)
    onDownloadCSV()
  }

  const renderTable = () => {
    if (summariesState.isFilterRemovedAll) {
      return (
        <SmallGuide
          icon="pray"
          className={style.guide}
          message="該当の結果はありません"
          description="Filter条件を変更してください"
        />
      )
    }
    if (summariesState.allSummaries.length === 0) {
      return (
        <SmallGuide
          icon="pray"
          className={style.guide}
          message="該当の日には結果はありません"
          description="日付を変更してください"
        />
      )
    }
    return (
      <TenantSalesReportSummaryTable
        className={style.table}
        summaries={summariesState.filteredSummaries}
        checkStatusList={summariesState.checkStatusList}
        isAllChecked={summariesState.isAllChecked}
        role={role}
        onChangeCheckStatus={handleChangeCheckStatus}
        onChangeAllCheckStatus={handleChangeAllCheckStatus}
      />
    )
  }

  return (
    <>
      <Box className={clsx(style.container, listStyles.container)}>
        <TenantSalesReportSummaryHeader
          className={style.header}
          breadcrumbs={breadcrumbs}
          storeName={storeName}
          storeCode={storeCode}
        />
        <Box
          sx={{ display: 'flex', flexWrap: 'nowrap' }}
          className={style.filterBar}
        >
          <SalesDateCount
            className={style.count}
            checkCount={summariesState.checkCount}
            filteredCount={summariesState.filteredSummaries.length}
            allCount={summariesState.allSummaries.length}
          />
          <UpdateButton className={style.reload} onUpdate={onReloadPage} />
          <TenantSalesReportSummarySearchDate
            date={date}
            onChangeDate={handleChangeDate}
            className={style.date}
          />
          <TenantSalesReportSummaryFilterBar
            role={role}
            onChangeFilterParams={handleChangeFilterParam}
          />
        </Box>
        {!isReadLoaded ? (
          <CenteringLoading />
        ) : (
          <Box className={style.tableWrapper}>{renderTable()}</Box>
        )}
        <Box className={style.footer}>
          {showUpdateSalesReportStatusByRole() && (
            <Box className={style.popup}>
              <PopupChild
                className={clsx(
                  summariesState.checkCount === 0 && style.disabled
                )}
                title="ステータス一括変更"
                placement="top-start"
              >
                <UpdateStatusMenu
                  className={style.updateStatusMenu}
                  onClickMenuItem={handleClickUpdateStatusType}
                  checkCount={summariesState.checkCount}
                />
              </PopupChild>
            </Box>
          )}
          {ENABLE_DOWNLOAD_CSV_ORG_CODES.includes(orgCode) &&
            showDownloadCSVByRole() && (
              <DownloadCSVButton
                role={role}
                summaries={summariesState.allSummaries}
                onClick={handleClickDisplayDownloadCSVModal}
              />
            )}
          {showTenantSalesReportPageByRole() && (
            <FinalizeReportButton
              className={style.finalizedReportButton}
              syncCount={summariesState.syncCount}
              checkCount={summariesState.checkCount}
              role={role}
              orgCode={orgCode}
              onClickStartFinalization={handleClickFinalization}
              onClickSend={handleClickSend}
            />
          )}
        </Box>
      </Box>
      <Modal
        title={`選択した${summariesState.checkCount}件の\nテナントのステータスを`}
        open={isUpdateStatusModalOpen}
        agreeButtonTitle="更新"
        disagreeButtonTitle="キャンセル"
        onClose={() => setIsUpdateStatusModalOpen(false)}
        onClickAgree={handleClickUpdateStatusButton}
        onClickDisagree={() => setIsUpdateStatusModalOpen(false)}
        disableBackdropClick={false}
      >
        <UpdateStatusConfirmDescription
          selectedUpdateStatusType={selectedUpdateStatusType}
        />
      </Modal>
      <Modal
        title={`${storeName} ${formatDateWithDayOfWeek(
          date
        )}のCSVファイルをダウンロードしますか？`}
        open={isDownloadCSVModalOpen}
        agreeButtonTitle="ダウンロードする"
        disagreeButtonTitle="キャンセルする"
        onClose={() => setIsDownloadCSVModalOpen(false)}
        onClickAgree={handleClickDownloadCSVButton}
        onClickDisagree={() => setIsDownloadCSVModalOpen(false)}
        disableBackdropClick={false}
      />
      {isUpdateLoading && (
        <LoadingModal
          open={isUpdateLoading}
          title="ステータス更新中"
          content="タブ削除・リロードを行うと更新されません。選択件数と通信環境によって30秒~60秒ほど時間がかかる場合がございます。"
        />
      )}
      {isCSVDownloading && (
        <LoadingModal
          open={isCSVDownloading}
          title="CSVダウンロード中"
          content="タブ削除・リロードを行うとダウンロードが中止されます。選択件数と通信環境によって30秒~60秒ほど時間がかかる場合がございます。"
        />
      )}
      {/* NOTE: テナント選択のチェック操作が止まったらisReadyToLoadNextImageがtrueになり、NextImageLoaderが呼び出され、確認画面の1ページ目に表示されるテナントの画像の事前ロードを開始する */}
      {isReadyToLoadNextImage &&
        firstDisplayTenantCode &&
        showTenantSalesReportPageByRole() && (
          <NextImageLoader
            orgCode={orgCode}
            storeCode={storeCode}
            selectedDate={date}
            nextTenantCode={firstDisplayTenantCode}
            onLoadComplete={() => setIsReadyToLoadNextImage(false)}
          />
        )}
    </>
  )
}

export default TemplatesTenantSalesReportSummary
